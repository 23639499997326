import React from "react";
import { Box } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import RequireAuth from "components/hoc/require-auth";
import {
  Login,
  ToursList,
  TourDetail,
  AddTour,
  EditTour,
  CustomersList,
  CustomerDetail,
  CurrentRoutes,
  AddCustomer,
  EditCustomer,
  AddOrder,
  OrderList,
  EditOrder,
  PastDeliveries,
  RoutesMap,
  RoutesMapDebug,
  HandoversList,
  ZipcodesList,
  ZipcodeAdd,
  ZipcodeEdit,
  RecentRoutes,
  ArchiveRoutes,
  ExportRoute,
  AddWithExistingCustomerData,
  ArchivedMap,
  CustomersArchive,
} from "views";
import { PATHS } from "../../util/appConstants";
import Redirect from "./Redirect";
import RecentsMap from "views/Routes/RecentsMap";
import DeliveryHistory from "views/DeliveryHistory/DeliveryHistory";

const AppRouter = () => {
  return (
    <Box minHeight="100vh" maxWidth="100%">
      <Box minHeight="100vh">
        <Switch>
          <Route exact path={PATHS.login} component={Login} />
          {/**tours */}
          <Route
            exact
            path={PATHS.tours.root}
            component={RequireAuth(ToursList)}
          />
          <Route
            exact
            path={PATHS.tours.add}
            component={RequireAuth(AddTour)}
          />
          <Route
            exact
            path={PATHS.tours.edit}
            component={RequireAuth(EditTour)}
          />
          <Route
            exact
            path={PATHS.tours.detail}
            component={RequireAuth(TourDetail)}
          />
          <Route
            exact
            // Tours is replaced with routes at UI and URL level
            path={PATHS.routes.current}
            component={RequireAuth(CurrentRoutes)}
          />
          <Route
            exact
            path={PATHS.routes.recent}
            component={RequireAuth(RecentRoutes)}
          />
          <Route
            exact
            path={PATHS.routes.archive}
            component={RequireAuth(ArchiveRoutes)}
          />
          <Route
            exact
            path={PATHS.routes.export}
            component={RequireAuth(ExportRoute)}
          />
          {/**customers */}
          <Route
            exact
            path={PATHS.customers.root}
            component={RequireAuth(CustomersList)}
          />
          <Route
            exact
            path={PATHS.customers.archive}
            component={RequireAuth(CustomersArchive)}
          />
          <Route
            exact
            path={PATHS.customers.detail}
            component={RequireAuth(() => (
              <CustomerDetail type="update" />
            ))}
          />
          <Route
            exact
            path={PATHS.customers.view}
            component={RequireAuth(() => (
              <CustomerDetail type="edit" />
            ))}
          />
          <Route
            exact
            path={PATHS.customers.add}
            component={RequireAuth(AddCustomer)}
          />
          <Route
            exact
            path={PATHS.customers.edit}
            component={RequireAuth(EditCustomer)}
          />
          <Route
            exact
            path={PATHS.customers.addWithExistingData}
            component={RequireAuth(AddWithExistingCustomerData)}
          />
          <Route
            exact
            path={PATHS.orders.root}
            component={RequireAuth(OrderList)}
          />
          <Route
            exact
            path={PATHS.orders.add}
            component={RequireAuth(AddOrder)}
          />
          <Route
            exact
            path={PATHS.orders.edit}
            component={RequireAuth(EditOrder)}
          />
          <Route
            exact
            path={PATHS.pastdeliveries}
            component={RequireAuth(PastDeliveries)}
          />
          <Route
            exact
            path={PATHS.deliveryhistory}
            component={RequireAuth(DeliveryHistory)}
          />
          <Route
            exact
            path={`${PATHS.tours.map}/:routeId?/:customerId?`}
            component={RequireAuth(RoutesMap)}
          />
          <Route
            exact
            path={`${PATHS.routes.mapRecent}/:routeId?/:customerId?`}
            component={RequireAuth(RecentsMap)}
          />
          <Route
            exact
            path={`${PATHS.routes.mapArchive}/:routeId?/:customerId?`}
            component={RequireAuth(ArchivedMap)}
          />
          <Route
            exact
            path={"/routes/map/debug/:routeId?"}
            component={RequireAuth(RoutesMapDebug)}
          />
          <Route
            exact
            path={PATHS.handovers.root}
            component={RequireAuth(HandoversList)}
          />
          <Route
            exact
            path={PATHS.zipcodes.root}
            component={RequireAuth(ZipcodesList)}
          />
          <Route
            exact
            path={PATHS.zipcodes.add}
            component={RequireAuth(ZipcodeAdd)}
          />
          <Route
            exact
            path={PATHS.zipcodes.edit}
            component={RequireAuth(ZipcodeEdit)}
          />
          <Redirect component={RequireAuth(Redirect)} />
        </Switch>
      </Box>
    </Box>
  );
};

export default AppRouter;
