export const PATHS = {
  root: "/",
  login: "/login",
  handovers: {
    root: "/handover-protocol",
  },
  zipcodes: {
    root: "/zipcodes",
    add: "/zipcodes/add",
    edit: "/zipcodes/edit/:id",
  },
  tours: {
    root: "/tours",
    add: "/tours/add",
    edit: "/tours/edit/:id",
    detail: "/tours/detail/:id",
    current: "/tours/current",
    recent: "/tours/recent",
    archive: "/tours/archive",
    map: "/map/current",
    export: "/tours/export",
  },
  routes: {
    current: "/routes/current",
    recent: "/routes/recent",
    archive: "/routes/archive",
    export: "/routes/export",
    mapRecent: "/routes/recent/map",
    mapArchive: "/routes/archive/map",
  },
  customers: {
    root: "/customers",
    add: "/customers/add",
    addWithExistingData: "/customers/add-with-existing-data/:id",
    edit: "/customers/edit/:id",
    detail: "/customers/detail/:id",
    view: "/customers/view/:id",
    archive: "/customers/archive",
  },
  orders: {
    root: "/orders",
    add: "/orders/add",
    edit: "/orders/edit/:id",
  },
  pastdeliveries: "/past-deliveries",
  deliveryhistory: "/delivery-history",
  customer: "/dash",
  dashboard: "/dashboard",
  addTour: "/tour/add",
  maps: "/map",
};

export const DEPOSIT_AGREEMENTS = {
  NONE: "None",
  BRING_KEY: "Bring key",
  KEY_BOX: "Keybox",
};
